<template>
  <div class="main-container">
    <!-- 面包屑 -->
    <div class="question-nav">
      <router-link :to="{ name: 'Home' }">首页</router-link>
      <span class="slash"> > </span>
      <span class="current">图书列表</span>
    </div>

    <div class="select-tab margin_bot_20">
      <Classify
        ref="classify"
        :classify_id="classify_id"
        :all="true"
        @changeClassify="changeCommonClassify"
      />
      <ul class="sort clear">
        <li :class="{ active: sort == 'all' }" @click="changeSort('all')">
          综合排序
        </li>
        <li :class="{ active: sort == 'id' }" @click="changeSort('id')">
          最新
        </li>
        <li :class="{ active: sort == 'hot' }" @click="changeSort('hot')">
          最热
        </li>
        <li
          class="sort-price"
          :class="{ active: sort == 'price' }"
          @click="changeSort('price')"
        >
          价格
        </li>
      </ul>
    </div>
    <div class="list">
      <router-link
        class="item f-left"
        :to="{ name: 'BookDetail', params: { id: item.id } }"
        v-for="item in list"
        :key="item.id"
      >
        <div class="child">
          <div class="clear">
            <img class="cover" :src="item.cover_img" />
            <div class="right">
              <img
                v-if="item.is_spell"
                class="coupon"
                src="@/assets/images/course/tuan@2x.png"
              />
              <img
                v-if="item.is_has_coupon"
                class="coupon"
                src="@/assets/images/course/quan@2x.png"
              />
              <div class="title">{{ item.name }}</div>
              <div class="author">作者：{{ item.author }}</div>
              <!-- <div class="introduce">{{item.instruction}}</div> -->
            </div>
          </div>
          <div class="clear bottom">
            <span v-if="item.price" class="f-left price">
              <Currency></Currency>
              <span v-if="item.is_spell">{{ item.spell_price | price }}</span>
              <span v-else>{{ item.price | price }}</span>
            </span>
            <span v-else class="f-left price">免费</span>
            <strike v-if="item.underlined_price" class="f-left underline-price">
              <Currency></Currency>
              <span>{{ item.underlined_price | price }}</span>
            </strike>
          </div>
        </div>
      </router-link>
      <div style="clear: both"></div>
    </div>
    <Empty v-if="list.length == 0" class="empty" title="暂无图书！" />
    <el-pagination
      background
      @current-change="handleCurrentChange"
      :current-page="page"
      :page-size="limit"
      layout="prev, pager, next, total, jumper"
      :total="total"
      v-if="list.length > 0"
    ></el-pagination>
  </div>
</template>
<script>
import Classify from "../../components/Classify.vue";

export default {
  name: "Book",
  components: { Classify },
  data() {
    return {
      classify: 0, //一级分类
      sub_classify: 0, //二级分类
      classifyList: [],
      sort: "id",
      list: [],
      page: 1,
      limit: 12,
      total: 0,
      direct: "desc",
      classify_id: 0, //一级专业分类
      subClassify: 0, //二级专业分类
    };
  },
  computed: {
    subClassifyList() {
      if (this.classify == 0) {
        return [];
      }
      return (
        this.classifyList.filter((c) => c.id == this.classify)[0].child || []
      );
    },
  },
  async created() {
    // this.classifyList = await this.$http.get("/api/app/book/classify");
    await this.getBooks();

    this.classify_id = this.$route.query.classify_id;
  },
  methods: {
    async getBooks() {
      let data = await this.$http.get(
        "/api/app/book/list/" + (this.subClassify || this.classify_id),
        {
          order: this.sort,
          classify_id: this.sub_classify || this.classify_id,
          page: this.page,
          limit: this.limit,
          sort: this.direct,
        }
      );
      this.list = data.list;
      this.total = data.total;
    },
    async changeCommonClassify(flag, item) {
      if (flag == 1) {
        this.classify_id = item.id;
        this.subClassify = null;
        this.$refs.classify.showChildClassify = true;
        this.$nextTick(() => {
          this.$refs.classify.childClassify = item.child;
        });
      } else {
        this.subClassify = item.id;
      }
      this.page = 1;
      await this.getBooks();
    },
    async changeClassify(classify) {
      this.classify = classify;
      this.page = 1;
      this.sub_classify = 0;
      await this.getBooks();
    },
    async changeSubClassify(subClassify) {
      this.sub_classify = subClassify;
      this.page = 1;
      await this.getBooks();
    },
    async changeSort(sort) {
      this.sort = sort;
      this.page = 1;
      if (this.sort == "price") {
        this.direct = this.direct == "desc" ? "asc" : "desc";
      } else {
        this.direct = "desc";
      }
      await this.getBooks();
    },
    async handleCurrentChange(page) {
      this.page = page;
      await this.getBooks();
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../../css/question.scss";
</style>

<style lang="scss" scoped>
.list {
  margin-top: 26px;
  .item {
    width: 280px;
    height: 327px;
    box-sizing: border-box;
    margin-bottom: 20px;
    margin-right: 26px;
    position: relative;
    .child {
      background: rgba(255, 255, 255, 1);
      // border-radius: 10px;
      padding: 16px;
      width: 100%;
      height: 100%;
      box-sizing: border-box;
      transition: all 0.4s;
      &:hover {
        margin-top: -10px;
      }
    }
    &:nth-child(4n) {
      margin-right: 0;
    }
    .cover {
      display: block;
      width: 110px;
      height: 161px;
      margin: 4px auto;
      box-shadow: 2px 2px 6px 0px rgba(0, 0, 0, 0.4);
    }
    .right {
      width: 226px;
    }
    .title {
      margin-top: 16px;
      height: 32px;
      font-size: 18px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: rgba(32, 36, 42, 1);
      line-height: 25px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      &:hover {
        color: $primary-color;
      }
    }
    .author {
      font-size: 14px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #999999;
    }
    .introduce {
      height: 60px;
      font-size: 13px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: rgba(140, 140, 140, 1);
      line-height: 20px;
      margin-top: 7px;
      overflow: hidden;
    }
    .bottom {
      padding-top: 10px;
    }
    .coupon {
      float: left;
      margin-right: 8px;
      width: 18px;
      height: 18px;
      margin-top: 3px;
    }
    .price {
      font-size: 17px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: rgba(231, 77, 61, 1);
      display: flex;
      align-items: center;
      span {
        line-height: 17px;
        height: 17px;
      }
      img {
        height: 17px;
      }
    }
    .underline-price {
      font-size: 12px;
      color: rgba(0, 0, 0, 0.45);
      font-family: PingFangSC-Semibold, PingFang SC;
      margin-left: 20px;
      display: flex;
      align-items: center;
      margin-top: 2px;
      span {
        line-height: 12px;
        height: 12px;
      }
      img {
        height: 12px;
      }
    }
  }
}
.empty {
  margin-bottom: 50px;
}
</style>
