<template>
  <div class="main-container">
    <div class="book-nav">
      <router-link :to="{name:'Course'}">首页</router-link>
      <span class="slash"> > </span>
      <router-link :to="{name:'Book'}">图书</router-link>
      <span class="slash"> > </span>
      <span class="current">{{book.book}}</span>
    </div>
    <div class="detail">
      <div class="cover">
        <img v-if="book.cover_img"
             :src="book.cover_img" />
      </div>
      <div class="title">
        <img v-if="book.is_spell"
             class="title_icon"
             src="@/assets/images/course/tuan@2x.png" />
        <img v-if="couponList.length"
             class="title_icon"
             src="@/assets/images/course/quan@2x.png" />
        {{book.book}}
      </div>
      <div class="author-wrap">
        <span class="author">作者：{{book.author}}</span>
        <div class="introduce">图书简介：{{book.instruction}}</div>
        <!-- <span class="line">|</span>
        <span class="buy-num">{{book.sales_num+book.sales_base}}人购买</span>
        <span class="broswer-num">{{book.browse_num+book.browse_base}}人浏览</span> -->
      </div>
      <div class="clear activity"
           v-if="(book.is_vip_course&&$store.state.commonSetting.switch_vip_module === 1)||couponList.length || gifts.length || book.gifts_integral">
        <span class="f-left"
              style="margin-right:21px;">优惠</span>
        <div class="f-left">
          <div class="clear coupon"
               v-if="book.gifts_integral">
            <img class="f-left icon_youhui"
                 src="@/assets/images/jifen.png"
                 style="width:58px;" />
            <div class="f-left jifen-right">
              购买可得{{book.gifts_integral}}积分
            </div>
          </div>
          <div class="clear coupon"
               v-if="gifts.length">
            <img class="f-left icon_youhui"
                 src="@/assets/images/gifts.png"
                 style="width:58px;" />
            <div class="f-left jifen-right">
              <div class="actives-item">
                <span v-for="(item,index) in gifts"
                      :key="index">{{getGiftType(item.gift_type)}}
                  <em v-if="index!==gifts.length-1">、</em>
                </span>
                <span class="arrow-right"
                      @click="getGifts"> 查看
                  <i class="el-icon-arrow-right"></i></span>
              </div>
            </div>
          </div>
          <div class="clear coupon"
               v-if="couponList.length">
            <img class="f-left icon_youhui"
                 src="@/assets/images/youhui.png"
                 style="width:58px;" />
            <div class="f-left coupon-right">
              <div class="quan clear"
                   :class="{'used':coupon.user_has_coupon}"
                   v-for="coupon in couponList"
                   :key="coupon.id">
                <span class="name f-left">满{{coupon.full_reduction/100}}减{{coupon.discounted_price/100}}</span>
                <span class="get f-right"
                      v-if="!coupon.user_has_coupon"
                      @click="getCoupon(coupon.id)">领取</span>
                <span class="has-get f-right"
                      v-else>已领取</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="browse_num">
        <span class="count">{{book.sales_num+book.sales_base}}</span>
        <span>人已购买</span>
        <span class="hor-line">|</span>
        <span class="count">剩余库存:{{book.stock}}</span>
      </div>
      <div class="price-wrap">
        <span class="price">
          <Currency v-if="book.price"></Currency>
          <span v-if="book.price">{{book.price|price}}</span>
          <span v-else>免费</span>
        </span>
        <!-- <span class="ship-price">
          <span>运费：</span>
          <Currency v-if="book.ship_price"></Currency>
          <span v-if="book.ship_price">{{book.ship_price|price}}</span>
          <span v-else>免费</span>
        </span> -->
      </div>
      <!-- <div class="clear coupon">
        <div
          class="quan clear"
          :class="{'used':coupon.user_has_coupon}"
          v-for="coupon in couponList"
          :key="coupon.id"
        >
          <span class="name f-left">满{{coupon.full_reduction/100}}减{{coupon.discounted_price/100}}</span>
          <span class="get f-right" v-if="!coupon.user_has_coupon" @click="getCoupon(coupon.id)">领取</span>
          <span class="has-get f-right" v-else>已领取</span>
        </div>
      </div> -->
      <!-- <div class="buy-num-wrap">
        <img src="../../images/book/jian@2x.png" style="width:34px;" @click="changeNum(-1)" />
        <span class="num">{{num}}</span>
        <img src="../../images/book/jia@2x.png" style="width:34px;" @click="changeNum(1)" />
        <span class="stock">库存：{{book.stock-book.sales_num}}本</span>
      </div> -->
      <div class="spell"
           v-if="book.spell_price && book.spell_price>0">
        <div class="btn-buy btn-buy-spell">
          <div class="price">
            <Currency></Currency>
            <span>{{book.spell_price/100}}</span>
          </div>
          <div class="label">我要开团</div>
          <div class="spell-qr">
            <div class="header">手机扫码开团</div>
            <vue-qr :text="shareUrl"
                    :size="152"
                    class="qrcode"></vue-qr>
          </div>
        </div>
        <div class="btn-buy"
             @click="goToBuy">
          <div class="price">
            <Currency></Currency>
            <span>{{book.price/100}}</span>
          </div>
          <div class="label">单独购买</div>
        </div>
      </div>
      <div v-else
           class="btn-buy"
           @click="goToBuy">立即购买</div>
      <!-- <div class="btn-buy" @click="goToBuy">立即购买</div> -->
      <div class="operate">
        <a class="collect collect-none"
           v-if="!book.is_collected"
           @click="collect">收藏</a>
        <a class="collect collect-has"
           v-else
           @click="cancelCollect">已收藏</a>
        <a href="javascript:void(0)"
           class="share"
           @click="shareBook">分享</a>
      </div>
    </div>
    <div class="main">
      <el-tabs class="left book_tab"
               v-model="activeName">
        <el-tab-pane label="图书详情"
                     name="detail">
          <div class="book-detail"
               v-html="book.details"></div>
        </el-tab-pane>
        <el-tab-pane :label="'图书评价(' + (comments.length > 999 ? '999+' : comments.length) + ')'"
                     name="comment">
          <div class="comment-item"
               v-for="comment in comments"
               :key="comment.id">
            <div class="header">
              <img class="avatar"
                   :src="comment.avatar" />
              <span class="nickname">{{comment.nickname}}</span>
              <div style="position:relative;">
                <el-rate v-model="comment.grade"></el-rate>
                <div style="position:absolute;left:0;top:0;right:0;bottom:0;z-index:1;"></div>
              </div>
            </div>
            <div class="content">{{comment.content}}</div>
            <div class="time">{{new Date(parseInt(comment.created_at)*1000).format("yyyy-MM-dd hh:mm")}}</div>
          </div>
          <el-pagination background
                         @current-change="handleCurrentChange"
                         :current-page="page"
                         :page-size="limit"
                         layout="prev, pager, next, total, jumper"
                         :total="total"
                         v-if="total>0"></el-pagination>
          <Empty v-if="comments.length==0"
                 title="暂无图书评价信息哦！" />
        </el-tab-pane>
      </el-tabs>
      <div class="right">
        <div class="header">
          <!-- <img src="../../images/book/book@3x.png" /> -->
          <span>推荐图书</span>
        </div>
        <div class="recommend"
             v-for="item in recommends"
             :key="item.id">
          <div class="center cover-wrap">
            <router-link :to="{name:'BookDetail',params:{id:item.id}}">
              <img class="cover"
                   :src="item.cover_img" />
            </router-link>
          </div>
          <router-link :to="{name:'BookDetail',params:{id:item.id}}">
            <div class="title">{{item.name}}</div>
          </router-link>
          <div class="author">作者：{{item.author}}</div>
          <!-- <div class="instruction">{{item.instruction}}</div> -->
          <router-link :to="{name:'BookDetail',params:{id:item.id}}">
            <div class="price-wrap">
              <div class="price">
                <Currency v-if="item.price"></Currency>
                <span v-if="item.price">{{item.price|price}}</span>
                <span v-else>免费</span>
              </div>
            </div>
          </router-link>
        </div>
      </div>
    </div>
    <el-dialog title="分享"
               :visible.sync="showShareModal"
               width="400px">
      <vue-qr :text="shareUrl"
              :size="200"
              class="center"></vue-qr>
      <span slot="footer"
            class="dialog-footer">
        <el-button type="primary"
                   @click="showShareModal = false">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import VueQr from "vue-qr";
export default {
  name: "BookDetail",
  components: {
    VueQr
  },
  data () {
    return {
      id: this.$route.params.id,
      book: {},
      couponList: [],
      num: 1,
      showShareModal: false,
      shareUrl: "",
      activeName: "detail",
      comments: [],
      recommends: [],
      page: 1,
      limit: 10,
      total: 0,
      gifts: [],
      has_join_spell: false
    };
  },
  async created () {
    await this.getComment();
    await this.getData();
    await this.incrementBrosweNum();
  },
  methods: {
    async incrementBrosweNum () {
      await this.$http.put("/api/app/book/pv/" + this.id);
      this.book.browse_num++;
    },
    // 获取拼团信息
    async getGroupInfo () {
      let group_info = await this.$http.get(
        `/api/app/spellGroupInfo/${this.id}/book`
      );
      if (group_info.spell_price) {
        this.book.spell_price = group_info.spell_price;
        this.has_join_spell = !!group_info.is_join_spell;
      }
    },
    async getData () {
      let data = await this.$http.get("/api/app/book/detail/" + this.id);
      this.book = data;
      document.title = this.book.book;
      await this.getGroupInfo();
      this.couponList = data.coupon.all ? data.coupon.all : [];
      this.gifts = data.gifts ? data.gifts : [];
      this.recommends = await this.$http.get(
        "/api/app/book/recommend/" + this.id
      );
      this.shareUrl = this.$store.state.webConfig.H5_address + "/book-detail?id=" + this.id;

    },
    async collect () {
      this.book.collect_id = await this.$http.post(
        "/api/app/collect",
        {
          book_id: this.id,
          type: 4
        },
        true
      );
      this.book.is_collected = true;
      await this.$success("收藏成功");
    },
    async cancelCollect () {
      await this.$http.put(
        "/api/app/collect/cancel/" + this.book.collect_id + "/4"
      );
      this.book.is_collected = false;
      await this.$success("取消收藏成功");
    },
    shareBook () {
      this.showShareModal = true;
    },
    changeNum (step) {
      let newNum = this.num + step;
      if (newNum < 1 || newNum > this.book.stock - this.book.sales_num) {
        return;
      }
      this.num = newNum;
    },
    goToBuy () {
      if (this.has_join_spell) {
        this.$info("您已经参与拼团了，不能单独购买。");
        return;
      }
      if (this.num > this.book.stock - this.book.sales_num) {
        this.$info("没有库存了~");
        return;
      }
      this.$router.push({
        name: "OrderConfirm",
        params: { id: this.id },
        query: { type: 12, num: this.num }
      });
    },
    async getCoupon (id) {
      await this.$http.get("/api/app/userGetCoupon/" + id);
      this.couponList.filter(c => c.id == id)[0].user_has_coupon = 1;
      this.$message({
        message: "领取成功",
        type: "success"
      });
    },
    async getComment () {
      let data = await this.$http.get("/api/app/book/comments/" + this.id, {
        page: this.page,
        limit: this.limit
      });
      this.comments = data.list;
      this.total = data.total;
    },
    async handleCurrentChange (page) {
      this.page = page;
      await this.getComment();
    }
  },
  watch: {
    async activeName () {
      if (this.activeName == "comment") {
        await this.getComment();
      }
    },
    async $route () {
      this.id = this.$route.params.id;
      await this.getComment();
      await this.getData();
      await this.incrementBrosweNum();
    }
  }
};
</script>
<style lang="scss" scoped>
.book-nav {
  height: 62px;
  // background-color: white;
  line-height: 62px;
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
  // margin: 30px 0;
  // padding-left: 24px;
  .slash {
    padding: 0 8px;
  }
  a {
    color: #333333;
  }
}
.detail {
  background-color: white;
  box-shadow: 0px 16px 20px 0px rgba(0, 0, 0, 0.07);
  // border-radius: 20px;
  // margin-left: 30px;
  padding: 30px 30px 30px 213px;
  position: relative;
  min-height: 270px;
  .cover {
    position: absolute;
    // box-shadow: 2px 2px 6px 0px rgba(0, 0, 0, 0.4);
    width: 330px;
    height: 275px;
    top: 30px;
    left: 30px;
    background-color: #f5f5f5;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      width: 148px;
      height: 220px;
    }
  }
  .title {
    font-size: 24px;
    margin-left: 180px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #333333;
    line-height: 25px;
    letter-spacing: 1px;
    margin-bottom: 10px;
    max-width: 700px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    .title_icon {
      width: 24px;
      vertical-align: bottom;
    }
    .title_icon:nth-child(2) {
      margin-left: 10px;
    }
  }
  .browse_num{
    margin-left: 180px;
    font-size: 14px;
    margin-bottom: 10px;
    .hor-line{
      display: inline-block;
      margin: 0 10px;
    }
  }
  .author-wrap {
    margin-left: 180px;
    font-size: 14px;
    font-family: PingFangSC-Medium, PingFang SC;
    color: #8c8c8c;
    line-height: 20px;
    letter-spacing: 1px;
    margin-bottom: 10px;
    .author {
      color: #999999;
    }
    .introduce {
      margin-top: 6px;
      min-height: 40px;
    }
    .line {
      margin: 0 22px;
    }
    .buy-num {
      margin-right: 50px;
    }
  }
  .price-wrap {
    margin-left: 180px;
    display: flex;
    align-items: center;
    margin-bottom: 50px;
    .price {
      display: flex;
      align-items: center;
      margin-right: 10px;
      span {
        font-size: 24px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: rgba(238, 31, 31, 1);
        line-height: 32px;
        height: 32px;
        display: inline-block;
      }
      img {
        height: 24px;
      }
      span,
      img {
        vertical-align: middle;
      }
    }
    .ship-price {
      display: flex;
      align-items: center;
      span {
        height: 20px;
        font-size: 14px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: rgba(140, 140, 140, 1);
        line-height: 20px;
        display: inline-block;
      }
      img {
        height: 14px;
      }
      span,
      img {
        vertical-align: middle;
      }
    }
  }
  .coupon {
    margin-left: 180px;
    min-height: 34px;
    .quan {
      width: 159px;
      height: 24px;
      display: inline-block;
      margin-left: 12px;
      // background-image: url(../../images/youhui-useable.png);
      color: #ff323f;
      line-height: 24px;
      margin-bottom: 5px;
      border: 1px solid #f1000e;
      border-radius: 3px;
      position: absolute;
      position: relative;
      &::before {
        content: "";
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background: #fff;
        position: absolute;
        top: 50%;
        left: -5px;
        transform: translateY(-50%);
        border: 1px solid #f1000e;
      }
      &::after {
        content: "";
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background: #fff;
        position: absolute;
        top: 50%;
        right: -5px;
        transform: translateY(-50%);
        border: 1px solid #f1000e;
      }
      .name {
        width: 94px;
        display: inline-block;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        text-align: center;
      }
      .get {
        // color: white;
        cursor: pointer;
        margin-right: 15px;
      }
      &.used {
        background-image: url(../../images/youhui-used.png);
        border: none;
        border-bottom: 1px solid #e7e9e9;
        .name {
          color: #b7b7b7;
        }
        .has-get {
          color: #b7b7b7;
          margin-right: 10px;
        }
        &::before {
          border: 1px solid #e7e9e9;
        }
        &::after {
          border: 1px solid #e7e9e9;
        }
      }
    }
  }
  .activity {
    margin-left: 180px;
    font-size: 14px;
    .coupon {
      margin-left: 0px;
      .icon_youhui {
        width: 38px !important;
        height: 20px;
      }
      .arrow-right {
        float: right;
        cursor: pointer;
      }
      .jifen-right {
        margin-left: 13px;
        width: 380px;
      }
      .coupon-right {
        width: 380px;
        // padding-left: 20px;
      }
    }
  }
  .buy-num-wrap {
    margin-left: 180px;
    display: flex;
    align-items: center;
    margin-bottom: 25px;
    img {
      cursor: pointer;
    }
    .num {
      display: inline-block;
      box-sizing: border-box;
      width: 95px;
      height: 34px;
      background: rgba(255, 255, 255, 1);
      border-radius: 6px;
      border: 1px solid rgba(91, 96, 102, 1);
      text-align: center;
      line-height: 32px;
      margin: 0 10px;
    }
    .stock {
      background: rgba(244, 244, 244, 1);
      border-radius: 17px;
      height: 20px;
      font-size: 14px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: rgba(140, 140, 140, 1);
      line-height: 20px;
      padding: 7px 20px;
      margin-left: 20px;
    }
  }
  .btn-buy {
    position: absolute;
    bottom: 27px;
    margin-left: 180px;
    width: 150px;
    height: 50px;
    background: #3b90ff;
    border-radius: 2px;
    font-size: 17px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 400;
    color: #fff;
    text-align: center;
    line-height: 50px;
    cursor: pointer;
  }
  .operate {
    position: absolute;
    bottom: 46px;
    right: 30px;
    line-height: 1;
  }
  .collect,
  .share {
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #8c8c8c;
    cursor: pointer;
  }
  .collect {
    background-size: 15px 14px;
    background-repeat: no-repeat;
    background-position: left 1px;
    padding-left: 22px;
    margin-right: 21px;
  }
  .collect-none {
    background-image: url("~@/assets/images/course/collect-none@2x.png");
  }
  .collect-has {
    background-image: url("~@/assets/images/course/collect@3x.png");
  }
  .share {
    background-image: url("~@/assets/images/course/share@2x.png");
    background-size: 14px 14px;
    background-repeat: no-repeat;
    background-position: left 1px;
    padding-left: 22px;
    color: $primary-color;
  }
  // 拼团按钮
  .spell {
    position: relative;
    margin-left: 270px;
    .btn-buy {
      text-align: center;
      width: 150px;
      height: 50px;
      margin-left: 0;
      background: rgba(59, 144, 255, 0.2);
      border-radius: 5px;
      display: inline-block;
      margin-right: 20px;
      box-sizing: border-box;
      padding: 5px;
      color: $primary-color;
      position: static;
      cursor: pointer;
      &:nth-child(2) {
        margin-right: 0;
      }
      &:hover {
        .spell-qr {
          display: block;
        }
      }
      .price {
        height: 20px;
        font-size: 14px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        line-height: 14px;
        img {
          width: auto;
          height: 12px;
          margin-right: 3px;
        }
        .spell-currency {
          height: 12px;
          width: auto;
        }
      }
      .label {
        height: 20px;
        font-size: 14px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        line-height: 20px;
      }
    }
    .spell-qr {
      position: absolute;
      width: 152px;
      z-index: 999;
      box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
      border-radius: 10px 7px 7px 7px;
      background-color: white;
      left: 0;
      top: 60px;
      display: none;

      .qrcode {
        line-height: 1;
      }
      .header {
        background: rgba(217, 217, 217, 1);
        height: 20px;
        font-size: 14px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: rgba(140, 140, 140, 1);
        line-height: 20px;
        padding: 16px 0 10px 0;
        border-radius: 10px 7px 0 0;
      }
      &::before {
        position: absolute;
        content: "";
        width: 0;
        height: 0;
        border: solid 8px transparent;
        border-bottom-color: rgba(217, 217, 217, 1);
        top: -16px;
        left: 34px;
      }
    }
  }
}
.main {
  margin-top: 30px;
  overflow: hidden;
  margin-bottom: 50px;
  .book_tab {
    /deep/ {
      .el-tabs__nav {
        margin-left: 0 !important;
      }
      .el-tabs__item {
        text-align: center;
        width: 160px;
        padding: 0 !important;
        height: 50px;
        line-height: 50px;
        &.is-active {
          background-color: #fff !important;
          color: $primary-color !important;
          border-left: 1px solid #e6e6e5;
          border-right: 1px solid #e6e6e5;
        }
      }
      .el-tabs__active-bar {
        top: 0;
        width: 160px !important;
      }
      .el-tabs__nav-wrap {
        background-color: #fbfbfb;
        height: 52px;
        line-height: 52px;
      }
      .el-tabs__nav-wrap::after {
        height: 0;
      }
    }
  }
  .left {
    width: 851px;
    float: left;
    box-sizing: border-box;
    background-color: white;
    // padding-top: 10px;
    .book-detail {
      padding: 32px;
      padding-top: 15px;
    }
    /deep/ {
      .el-tabs__nav {
        margin-left: 26px;
      }
      .el-tabs__item {
        font-size: 16px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 400;
        color: #999899 !important;
      }
    }
  }
  .right {
    float: right;
    width: 329px;
    .header {
      font-size: 16px;
      font-family: Adobe Heiti Std;
      font-weight: normal;
      color: rgba(91, 96, 102, 1);
      height: 52px;
      background-color: white;
      border-bottom: 1px solid #f4f4f4;
      padding: 0 16px;
      // margin-bottom: 15px;
      display: flex;
      align-items: center;
      img {
        margin-left: 20px;
        width: 14px;
        margin-right: 7px;
      }
      span {
        height: 16px;
        line-height: 16px;
        display: inline-block;
      }
    }
    .recommend {
      background-color: white;
      padding: 15px;
      // margin-bottom: 15px;
      border-bottom: 1px solid #f4f4f4;
      padding: 20px;
      height: 290px;
      position: relative;
      .cover {
        width: 143px;
        height: 180px;
        box-shadow: 2px 2px 6px 0px rgba(0, 0, 0, 0.4);
        margin-bottom: 12px;
      }
      .title {
        margin-bottom: 8px;
        font-size: 18px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 500;
        color: #333333;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
        &:hover {
          color: $primary-color;
        }
      }
      .instruction {
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(140, 140, 140, 1);
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 3;
        overflow: hidden;
      }
      .author {
        color: #999999;
        font-size: 14px;
        font-weight: 400;
      }
      .cover-wrap {
        // position: relative;
      }
      .price-wrap {
        position: absolute;
        left: 20px;
        bottom: -20px;
        // height: 200px;
        width: 100%;
        color: #f83738;
        // background: linear-gradient(
        //   to bottom,
        //   rgba(37, 37, 37, 0),
        //   rgba(37, 37, 37, 0.75)
        // );
      }
      .price {
        width: 100%;
        position: absolute;
        bottom: 27px;
        font-size: 18px;
        color: #f83738;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        img {
          height: 14px;
        }
        img,
        span {
          vertical-align: middle;
        }
      }
    }
  }
}
.comment-item {
  border-bottom: 1px solid #e8e8e8;
  padding: 16px 0;
  margin: 0 32px;
  &:last-child {
    border-bottom: none;
  }
  .header {
    display: flex;
    align-items: center;
    color: #595959;
    .avatar {
      width: 20px;
      height: 20px;
      border-radius: 100%;
      margin-right: 10px;
    }
    .nickname {
      margin-right: 20px;
      font-size: 16px;
    }
    .el-rate {
      display: inline-block;
    }
  }
  .content {
    padding: 16px 0;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.65);
  }
  .time {
    color: rgba(0, 0, 0, 0.25);
    font-size: 14px;
  }
  /deep/ {
    .el-icon-star-on,
    .el-icon-star-off {
      color: #ea7a2f !important;
    }
  }
}
</style>
<style lang="scss">
.book-detail img {
  max-width: 100%;
}
</style>
