<template>
  <div class="main-container">
    <div style="padding-top:24px;">优惠券可用图书：</div>
    <ul class="sort clear">
      <li :class="{'active':sort=='id'}" @click="changeSort('id')">最新</li>
      <li :class="{'active':sort=='hot'}" @click="changeSort('hot')">最热</li>
      <li class="sort-price" :class="{'active':sort=='price'}" @click="changeSort('price')">价格</li>
    </ul>
    <div class="list">
      <router-link
        class="item f-left"
        :to="{name:'BookDetail',params:{id:item.id}}"
        v-for="item in list"
        :key="item.id"
      >
        <div class="child">
          <div class="clear">
            <img class="f-left cover" :src="item.cover_img" />
            <div class="f-right right">
              <div class="title">{{item.name}}</div>
              <div class="introduce">{{item.instruction}}</div>
            </div>
          </div>
          <div class="clear bottom">
            <img v-if="item.has_coupon" class="coupon" src="../../images/book/coupon@2x.png" />
            <span v-if="item.price" class="f-right price">
              <Currency></Currency>
              <span>{{item.price|price}}</span>
            </span>
            <span v-else class="f-right price">免费</span>
            <strike v-if="item.underlined_price" class="f-right underline-price">
              <Currency></Currency>
              <span>{{item.underlined_price|price}}</span>
            </strike>
          </div>
        </div>
      </router-link>
      <div style="clear:both;"></div>
    </div>
    <Empty v-if="list.length==0" class="empty" title="暂无图书！" />
    <el-pagination
      background
      @current-change="handleCurrentChange"
      :current-page="page"
      :page-size="limit"
      layout="prev, pager, next, total, jumper"
      :total="total"
      v-if="list.length>0"
    ></el-pagination>
  </div>
</template>
<script>
export default {
  name: "Book",
  data() {
    return {
      sort: "id",
      list: [],
      page: 1,
      limit: 9,
      total: 0
    };
  },
  async created() {
    await this.getBooks();
  },
  methods: {
    async getBooks() {
      let data = await this.$http.get(
        "/api/app/couponGoods?type=2&coupon_id=" + this.$route.query.id,
        { order: this.sort, page: this.page, limit: this.limit }
      );
      this.list = data.list;
      this.total = data.total;
    },
    async changeSort(sort) {
      this.sort = sort;
      this.page = 1;
      await this.getBooks();
    },
    async handleCurrentChange(page) {
      this.page = page;
      await this.getBooks();
    }
  }
};
</script>
<style lang="scss" scoped>
.select-tab {
  margin-top: 30px;
  padding: 24px 0 0 0;
  .item {
    .left {
      width: 72px;
      margin-right: 24px;
      p {
        height: 22px;
        line-height: 22px;
      }
    }
    .right {
      margin-left: 0;
      li {
        color: #8c8c8c;
        margin-left: 10px;
        margin-right: 10px;
        max-width: 80px;
        padding: 0 10px;
        &:first-child {
          margin-left: 0 !important;
        }
      }
      li.active {
        border-radius: 11px;
        background-color: $primary-color-light;
        color: $primary-color;
      }
      .child {
        margin: 0 30px 24px 32px;
        padding: 24px 22px 14px 22px;
        background: rgba(255, 255, 255, 1);
        box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.06);
        border-radius: 10px;
        p {
          color: #8c8c8c;
          margin: 0 10px 10px 10px;
          padding: 0;
          min-width: 0;
          padding: 0 10px;
          &.active {
            background-color: $primary-color-light;
            border-radius: 11px;
            color: $primary-color;
          }
          &:first-child {
            margin-left: 0 !important;
          }
        }
      }
      .select-tab-arrow {
        border-bottom-color: white;
        top: 24px;
      }
    }
  }
}
.sort {
  padding: 28px 0 18px 0;
  li {
    float: left;
    font-size: 13px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: rgba(32, 36, 42, 1);
    margin: 0 20px;
    cursor: pointer;
    &.active {
      background: rgba(91, 96, 102, 1);
      border-radius: 11px;
      padding: 0 10px;
      color: white;
      margin: 0 10px;
    }
    &:first-child {
      margin-left: 0 !important;
    }
    &.sort-price {
      position: relative;
      &::after {
        content: "";
        width: 7px;
        height: 12px;
        position: absolute;
        background-size: 100% 100%;
        background-image: url("../../images/book/sort@2x.png");
        top: 3px;
        right: -10px;
      }
    }
  }
}
.list {
  .item {
    width: 387px;
    height: 188px;
    box-sizing: border-box;
    margin-bottom: 20px;
    margin-right: 19px;
    position: relative;
    .child {
      background: rgba(255, 255, 255, 1);
      border-radius: 10px;
      padding: 15px 17px 19px 17px;
      width: 100%;
      height: 100%;
      box-sizing: border-box;
      transition: all 0.4s;
      &:hover {
        margin-top: -10px;
      }
    }
    &:nth-child(3) {
      margin-right: 0;
    }
    .cover {
      width: 87px;
      height: 107px;
      margin-top: 4px;
      box-shadow: 2px 2px 6px 0px rgba(0, 0, 0, 0.4);
    }
    .right {
      width: 246px;
    }
    .title {
      height: 49px;
      font-size: 15px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: rgba(32, 36, 42, 1);
      line-height: 25px;
      overflow: hidden;
      &:hover {
        color: $primary-color;
      }
    }
    .introduce {
      height: 60px;
      font-size: 13px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: rgba(140, 140, 140, 1);
      line-height: 20px;
      margin-top: 7px;
      overflow: hidden;
    }
    .bottom {
      padding-top: 20px;
    }
    .coupon {
      float: left;
      margin-right: 8px;
      width: 18px;
      height: 18px;
    }
    .price {
      font-size: 17px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: rgba(231, 77, 61, 1);
      display: flex;
      align-items: center;
      span {
        line-height: 17px;
        height: 17px;
      }
      img {
        height: 17px;
      }
    }
    .underline-price {
      font-size: 12px;
      color: rgba(0, 0, 0, 0.45);
      font-family: PingFangSC-Semibold, PingFang SC;
      margin-right: 5px;
      display: flex;
      align-items: center;
      margin-top: 5px;
      span {
        line-height: 12px;
        height: 12px;
      }
      img {
        height: 12px;
      }
    }
  }
}
</style>